<script setup lang="ts"></script>

<template>
    <blockquote class="badgr-badge" style="font-family: Helvetica, Roboto, Segoe, Calibri, sans-serif">
        <NuxtLink to="/documents-for-ordering#whitepapers">
            <img
                src="https://api.badgr.io/public/assertions/Z8YKzShnS6eA3DYR_kV1yg/image"
                alt="Instructure Badge"
                title="Instructure Badge"
                class="h-[100px]"
            />
        </NuxtLink>
    </blockquote>
</template>

<style scoped></style>
