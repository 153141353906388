<script lang="ts" setup>
import H2Heading from "~/components/H2Heading.vue";
import ButtonReverseOutline from "~/components/ButtonReverseOutline.vue";
import { STATES } from "../../constants/states";
import { type Ref } from "vue";

const props = defineProps({
    state: {
        type: String,
        default: "ga",
    },
});

const salesTeam: Ref<{ id: number; name: string; role: string; photo: object }[]> = ref([]);
salesTeam.value = await useHelper().getCollection("staff", `filter[taxonomy:states]=${props.state.toLowerCase()}`, 9);
</script>

<template>
    <div class="lg:flex">
        <div class="with-dots-bg flex flex-none items-center justify-center p-24 text-center text-white lg:w-1/2">
            <div class="flex-1">
                <H2Heading class="text-white">Need help?</H2Heading>
                <p class="mt-6">
                    Your {{ STATES[state ? state.toUpperCase() : "GA"] }} representatives are here to personally help
                    you determine the best solution for you.
                </p>

                <ButtonReverseOutline type="link" href="mailto:contact@americanbookcompany.com" class="my-10">
                    Email Our Sales Team
                </ButtonReverseOutline>

                <p>
                    Phone:
                    <NuxtLink to="tel:18882645877">(888) 264-5877</NuxtLink>
                    <br />
                    Fax: (866) 827-3240
                </p>
            </div>
        </div>
        <div class="flex flex-none items-center justify-center bg-gray-100 p-10 lg:w-1/2">
            <div class="grid flex-1 grid-cols-2 gap-14 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3">
                <div v-for="person in salesTeam" :key="`sales-team-${person.id}`" class="w-42 text-center text-sm">
                    <div
                        class="mx-auto h-32 w-32 overflow-hidden"
                        :class="
                            !person.photo || person.photo.url.toLowerCase().includes('portrait_placeholder')
                                ? 'rounded-full'
                                : ''
                        "
                    >
                        <img
                            :src="person.staff_photo ? person.staff_photo : '/images/home/Portrait_Placeholder.png'"
                            :srcset="`${person.staff_photo} 1x, ${person.staff_photo_2x} 2x`"
                            :alt="'Portrait of ' + person.name"
                            class="mx-auto h-full"
                        />
                    </div>
                    <div class="mt-4 font-semibold">{{ person.name }}</div>
                    <div class="text-abc-red">{{ person.role }}</div>
                    <div class="mt-1 flex justify-center">
                        <a v-if="person.phone" :href="`tel: ${person.phone}`">
                            <img src="/images/icons/phone-regular.svg" class="w-5" />
                        </a>
                        <a v-if="person.email" :href="`mailto: ${person.email}`" class="ml-3">
                            <img src="/images/icons/envelope-regular.svg" class="w-5" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.with-dots-bg {
    background-image: url("/images/home/help_bg_1300x600.png");
    @apply bg-cover bg-right-top bg-no-repeat;
}
</style>
